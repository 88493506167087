
import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Thumb = () => {

    const { file } = useStaticQuery(
        graphql`
            query {
                file(relativePath: { eq: "thumb.png" }) {
                    childImageSharp {
                        fixed {
                            src
                        }
                    }
                }
            }
        `
    )
    return (
        <img
            className={"image"}
            src={file.childImageSharp.fixed.src}
        />
    )
}
export default Thumb