import React, { useState, useEffect } from "react"
import Link from "gatsby-link"

import Layout from "../components/layout"
import Thumb from '../components/thumb-fixed-img'

const Favorites = () => {

    var favList = [{}];
    const getArray = typeof window !== "undefined" && window.localStorage.getItem('favorites') ? JSON.parse(window.localStorage.getItem('favorites') || '0') : {};
    //const getArray = [{}];
    for (var i = 0; i < getArray.length; i++) {
        let x = getArray[i];
        favList[i] = typeof window !== "undefined" && window.localStorage.getItem('favItem' + [x]) ? JSON.parse(window.localStorage.getItem('favItem' + [x]) || '') : {};
    }
    //const titles = Object.keys(favList[0]);
    //console.log(favList);

    const [favorites, setFavorites] = useState([]);
    //const getArray = JSON.parse(localStorage.getItem('favorites') || '0');

    useEffect(() => {
        const getArray = JSON.parse(localStorage.getItem('favorites') || '0');
        for (var i = 0; i < getArray.length; i++) {
            let x = getArray[i];
            favList[i] = typeof window !== "undefined" && window.localStorage.getItem('favItem' + [x]) ? JSON.parse(window.localStorage.getItem('favItem' + [x]) || '') : {};
        }
        if( getArray !== 0 ){
            setFavorites([...getArray]);
        }
    }, []);

    const addFav = (props) => {
        let array = favorites;
        let addArray = true;
        //console.log(props);

        array.map((item, key) => {
            if (item === props.objectID) {
                array.splice(key, 1);
                addArray = false;
            }
        });

        if (addArray) {
            array.push(props.objectID);
        }
        setFavorites([...array]);
        window.localStorage.setItem('favorites', JSON.stringify(favorites));
    
        var storage = window.localStorage.getItem('favItem' + (props.objectID) || 0);
        if( storage == null ) {
            window.localStorage.setItem(('favItem' + (props.objectID)), JSON.stringify(props));
        }

    };

    return(
        <Layout>
            <div className="favorites container">

                <h1 className="title">Favoriti</h1>

                <Link className="print-none" to="/products/">&lsaquo; Torna a Prodotti</Link>

                {/* Always use a ternary when coercing an array length */}
                {/* otherwise you might print out "0" to your UI */}
                {getArray.length ? (
                    <>
                    <button className="print print-none" onClick={() => window.print()}>Stampa</button>
                    <div className={"items"}>
                        {favList.map( product => {
                            return (
                                <div key={product.slug} className="item">

                                    <div className="image">
                                    {product.acf.product__image
                                        ?
                                            <img
                                                src={product.acf.product__image.localFile.childImageSharp.fixed.src}
                                                alt={product.title}
                                                className="image"
                                            />
                                        :
                                            <Thumb />
                                    }
                                    </div>

                                    <div className={"content"}>
                                        <h1 className={"title"}>{product.title}</h1>

                                        <div className={"data"}>

                                            <div className={"type"}>
                                                <h4 className={"title"}>Categorie</h4>
                                                <ul>
                                                    {product.type.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={"material"}>
                                                <h4 className={"title"}>Materiali</h4>
                                                <ul>
                                                    {product.material.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={"style"}>
                                                <h4 className={"title"}>Stile</h4>
                                                <ul>
                                                    {product.style.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={"hole"}>
                                                <h4 className={"title"}>Fori</h4>
                                                <ul>
                                                    {product.hole.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={"season"}>
                                                <h4 className={"title"}>Stagione</h4>
                                                <ul>
                                                    {product.season.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                            <div className={"genre"}>
                                                <h4 className={"title"}>Genere</h4>
                                                <ul>
                                                    {product.genre.map((c, index) => (
                                                        <li key={index}>{c.name}</li>
                                                    ))}
                                                </ul>
                                            </div>

                                        </div>

                                        <div className={"description"}>
                                            <h4 className={"title"}>Descrizione</h4>
                                            <div className={"text"} dangerouslySetInnerHTML={{__html: product.acf.product__description}} />
                                        </div>

                                        <div className="favorite">
                                            {favorites.includes(product.objectID) ? (
                                                <button className="is-favorite" onClick={() => addFav(product)}>
                                                    <span className="label">Add to</span>
                                                    <i className="gg-heart"></i>
                                                </button>
                                            ) : (
                                                <button onClick={() => addFav(product)}>
                                                    <span className="label">Remove to</span>
                                                    <i className="gg-heart"></i>
                                                </button>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            )
                        })}

                    </div>

                    <Link className="print-none" to="/products/">&lsaquo; Torna a Prodotti</Link>
                    <button className="print print-none" onClick={() => window.print()}>Stampa</button>
                    </>
                ) : (
                    <p>Non ci sono Favoriti. Aggiungi un elemento ai Favoriti.</p>
                )}
            </div>
        </Layout>
    )

}
export default Favorites